import React, { Component } from "react";
import SideBarNav from "../layout/sideNavigation";
import "./admin.css";
import moment from "moment";
import { MDBRow, MDBCol } from "mdbreact";

import { getAllContactus } from "../../components/apidata/api";
export default class AllContactus extends Component {
  constructor() {
    super();
    this.state = {
      allcontactus: [],
    };
  }

  async componentWillMount() {
    const contactus = await getAllContactus();
    contactus.sort().reverse();

    if (contactus) {
      this.setState({ allcontactus: contactus });
    }
  }

  render() {
    const aa = {
      backgroundColor: "#fff",
      borderRadius: " 20px",
    };
    return (
      <div>
        <div className="flexible-content">
          <SideBarNav />
          <div id="content" style={aa} className="p-2">
            <MDBRow>
              <MDBCol md="12">
                <h4 className="mt-2">Contactus</h4>
                {/* {this.state.allcontactus ? this.state.allcontactus.map((contactus, index) => {

                                    const { username, message, subject, date  ,email} = contactus
                                    return (
                                        <p className="grey p-2 card-body">
                                            <h6><strong>Subject:</strong>&nbsp;&nbsp;{subject}</h6>
                                            <strong>Message:</strong>&nbsp;&nbsp; {message} <br/>
                                            <strong>Email:</strong>&nbsp;&nbsp;<span>{email}</span> 
                                            <br></br>
                                          BY &nbsp;&nbsp;{username} on {moment(date).format("DD/MM/YY")}
                                        </p>
                                    )
                                }) : <p>No contactus</p>} */}

                {this.state.allcontactus ? (
                  this.state.allcontactus
                    .slice()
                    .reverse()
                    .map((contactus, index) => {
                      const {
                        username,
                        message,
                        subject,
                        date,
                        email,
                      } = contactus;
                      return (
                        <p className="grey p-2 card-body" key={index}>
                          <h6>
                            <strong>Subject:</strong>&nbsp;&nbsp;{" "}
                            <span style={{ fontSize: "30", fontWeight: "800" }}>
                              {subject}
                            </span>
                          </h6>
                          <strong>Message:</strong>&nbsp;&nbsp;{message} <br />
                          <strong>Email:</strong>&nbsp;&nbsp;
                          <span>{email}</span>
                          <br />
                          BY &nbsp;&nbsp;{username} on{" "}
                          {moment(date).format("DD/MM/YY")}
                        </p>
                      );
                    })
                ) : (
                  <p>No contactus</p>
                )}
              </MDBCol>
            </MDBRow>
          </div>
        </div>
      </div>
    );
  }
}
