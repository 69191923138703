import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class UserFooter extends Component {
  render() {
    const FTstyle = {
      backgroundColor: "#f1f1f1!important",
      textAlign: "center",
    };
    return (
      <div style={{ left: 0, bottom: 0, right: 0 }}>
        {/* <!-- Footer --> */}

        <footer style={FTstyle} className="page-foote font-small mt- ">
          {/* <!-- Copyright --> */}
          <div
            style={{ backgroundColor: "#f1f1f1", opacity: "1", color: "grey" }}
          >
            &copy; {new Date().getFullYear()} &nbsp; with{" "}
            <span style={{ color: "red", fontSize: "20px" }}>
              &hearts; &nbsp;
            </span>
            <Link to="/" className="text-primary">
              | ProjectVersity
            </Link>
            |
            <Link to="/disclaimer" className="text-primary">
              Disclaimer
            </Link>{" "}
            |
            <Link to="/faq" className="text-primary">
              FAQ
            </Link>{" "}
            |{" "}
            <Link to="/support" className="text-primary" target="_blank ">
              Donate
            </Link>{" "}
            |
            <Link to="/privacypolicy" className="text-primary">
              Privacy policies
            </Link>{" "}
            |{" "}
            <Link to="/terms" className="text-primary">
              Terms and Conditions
            </Link>
            |
            <Link to="/contactus" className="text-primary">
              Contact Us
            </Link>{" "}
            |
            <Link to="/complain" className="text-primary">
              Report Issue |
            </Link>
          </div>

          {/* <!-- Copyright --> */}
        </footer>
        {/* <!-- Footer --> */}
      </div>
    );
  }
}
