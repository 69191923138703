import React, { Component } from "react";
import ProcessLoader from "../../assets/ProcessLoader";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import axios from "axios";
export default class ForgetPass extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      info: "",
      emailnewuser: "",
      resendinfo: "",
      error: false,
      token: false,
      isProcess: false,
      isPasswordShown: false,
      resend: false,
    };
  }

  componentDidMount(e) {
    const newUserEmail = localStorage.getItem("newUserEmail");
    const newUserName = localStorage.getItem("newUserName");
    this.setState({ emailnewuser: newUserEmail, newusername: newUserName });
  }

  async handleResendEmail() {
    this.setState({ isProcess: true });
    const mail = {
      email: this.state.emailnewuser,
      name: this.state.newusername,
    };
    axios
      .post("/resendlink", mail)
      .then((res) => {
        this.setState({ resendinfo: res.data.message, isProcess: false });
        this.setState({ resend: true });
        console.log(res);
      })

      .catch((err) => {
        console.log(err);
        this.setState({ resendinfo: err, isProcess: false });
      });
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.resend === true) {
        this.props.history.push("/signin");
      }
    }, 5000);
  }

  render() {
    const token = localStorage.getItem("token");
    const newUserResponse = localStorage.getItem("confirmnewuser");

    const { newUserEmail, resendinfo } = this.state;
    console.log(newUserEmail);
    return (
      <div>
        <div className="container text-center mt-5" style={{ width: "100%" }}>
          <div className="alert alert-success mt-5">
            {resendinfo == "" ? <p>{newUserResponse}</p> : <p>{resendinfo}</p>}
          </div>

          <Link className="btn btn-sm btn-primary" to="/signin">
            <span> Sign In</span>
          </Link>

          {/* move this to landing page */}
          <div className="form-group mt-2">
            if you have wait for 1 min and no email
            <input
              type="hidden"
              className="form-control"
              placeholder="Enter your email"
              value={newUserEmail}
            />
          </div>

          <button
            className="btn btn-sm btn-primary"
            onClick={this.handleResendEmail.bind(this)}
          >
            {this.state.isProcess === true ? (
              <ProcessLoader />
            ) : (
              <span> resend Email</span>
            )}
          </button>
        </div>
      </div>
    );
  }
}
